
import Vue from "vue";

import { Building, Breadcrumb } from "@/interfaces";

import http from "@/http";

export interface DataType {
  building?: Building;
}

export default Vue.extend({
  data(): DataType {
    return {
      building: undefined,
    };
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      if (this.building == null) {
        return [];
      }

      return [
        {
          text: "ホーム",
          to: "/",
        },
        {
          text: "建物",
          to: "/buildings",
          exact: true,
        },
        {
          text: `[${this.building.code}]${this.building.name}`,
        },
      ];
    },
  },
  watch: {
    async $route() {
      await this.fetch();
    },
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      this.building = undefined;

      const { id } = this.$route.params;

      const url = `buildings/${id}`;

      const { data } = await http.get<Building>(url);

      this.building = data;
    },
  },
});
